"use client"

import { useEffect } from "react"
import type { NextPage } from "next"
import Error, { type ErrorProps } from "next/error"
import * as Sentry from "@sentry/nextjs"
import { PublicEnvScript } from "next-runtime-env"

const CustomErrorComponent: NextPage<ErrorProps> = (props) => {
  return <Error statusCode={props.statusCode} />
}

CustomErrorComponent.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData)

  return Error.getInitialProps(contextData)
}

export default function GlobalError(props: { error: unknown }) {
  useEffect(() => {
    Sentry.captureException(props.error)
  }, [props.error])

  return (
    <html>
      <head>
        <PublicEnvScript />
      </head>
      <body>
        <CustomErrorComponent statusCode={500} title="Error" />
      </body>
    </html>
  )
}
